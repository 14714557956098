<template>
   <div class="layout-px-spacing" v-if="
        this.$can('admin') ||
        this.$can('office') ||
        this.$can('technik-manager') ||
        this.$can('obchodnik-manager') ||
        this.$can('rola-pobocka') ||
        this.$can('skladnik') ||
        this.$can('kurier') ||
        this.$can('infolinka') ||
        this.$can('technik')
   ">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">Používateľ</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>Edit</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div class="col-lg-12 layout-top-spacing">
            <div class="panel">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-12">
                        <h3>Editovať objednávku</h3>
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form v-if="orderActual.movements" @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-0">
                           <b-form-row class="over-1">
                              <b-form-group label="Názov objednávky" class="col-md-12">
                                 <b-form-input :state="validateState('name')" v-model="$v.form.name.$model" type="text"
                                               placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group label="Identifikátor" class="col-md-6 d-none">
                                 <b-form-input :disabled="orderActual.movements.length > 0" :state="validateState('identifier')" v-model="$v.form.identifier.$model" type="text"
                                               placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row class="over-1">
                              <b-form-group class="col-md-6" label="Objednávateľ">
                                 <b-form-input disabled v-if="form.from_user_name && !$can('admin') && !$can('office') && !$can('technik-manager') && !$can('obchod-manager') && !$can('rola-pobocka')" :state="validateState('from_user_name')" v-model="form.from_user_name" type="text" readonly placeholder=""></b-form-input>
                                 <multiselect :disabled="orderActual.movements.length > 0" v-else :max-height="200" :showNoResults="false" :state="validateState('from_user_id')" v-model="$v.form.from_user_id.$model" deselectLabel="Vymazať" selectLabel="Vybrať" selectedLabel="Vybrané" tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="users" :multiple="false" :closeOnSelect="true"></multiselect>
                              </b-form-group>
                              <b-form-group class="col-md-6" label="Dodávateľ">
                                 <multiselect :max-height="200" :disabled="orderActual.movements.length > 0" :showNoResults="false" v-model="form.to_user_id" deselectLabel="Vymazať" selectLabel="Vybrať" selectedLabel="Vybrané" tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="usersWithoutMe" :multiple="false" :closeOnSelect="true"></multiselect>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-12" label="Produkty">
                                 <div class="form-repeater w-100 custom-table">
                                    <table class="table table-responsive-detail">
                                       <thead>
                                          <tr>
                                             <th style="width: 30%;min-width: 200px">Názov</th>
                                             <th style="width: 30%;min-width: 200px">Počet</th>
                                             <th style="width: 30%;min-width: 200px">Poznámka</th>
                                             <th v-if="!orderActual.movements.length > 0" class="text-center">Odstrániť</th>
                                          </tr>
                                       </thead>
                                       <tbody class="form-repeater">
                                          <tr v-for="(row, index) in form.rows" :key="index">
                                             <td>
                                                <multiselect :disabled="orderActual.movements.length > 0" v-if="items.some(item => item.id === row.id) || row.id === 0" :max-height="200" :showNoResults="false" v-model="form.rows[index]" deselectLabel="Vymazať" selectLabel="Vybrať" selectedLabel="Vybrané" tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="items" :multiple="false" :closeOnSelect="true"></multiselect>
                                                <b-form-input :disabled="orderActual.movements.length > 0" v-else v-model="row.name" type="text" placeholder="Zadajte názov položky"></b-form-input>
                                             </td>
                                             <td>
                                                <b-input-group v-if="items.some(item => item.id === row.id) || row.id === 0">
                                                   <template #append>
                                                      <b-input-group-text v-if="row.id.unit"><strong v-if="row.id" class="">{{ row.id.unit.name }}</strong></b-input-group-text>
                                                      <b-input-group-text v-else><strong v-if="row.id" class="">{{ row.unit.name }}</strong></b-input-group-text>
                                                   </template>
                                                   <b-form-input :disabled="orderActual.movements.length > 0" v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                                <b-input-group v-else>
                                                   <template #append>
                                                      <multiselect :disabled="orderActual.movements.length > 0" class="unit-multiselect" :max-height="200" :showNoResults="false" v-model="row.unit" deselectLabel="Vymazať" selectLabel="Vybrať" selectedLabel="Vybrané" tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name" track-by="id" :can-deselect="false" :allow-empty="false" :options="units" :multiple="false" :closeOnSelect="true"></multiselect>
                                                   </template>
                                                   <b-form-input :disabled="orderActual.movements.length > 0" v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                             </td>
                                             <td>
                                                <b-form-input v-model="row.note" :disabled="orderActual.movements.length > 0" type="text" placeholder=""></b-form-input>
                                             </td>
                                             <td class="text-center align-middle" v-if="orderActual.movements.length < 1">
                                                <b-button variant="outline-danger" @click="removeRow(index)">
                                                   <svg
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="24"
                                                     height="24"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     stroke-width="2"
                                                     stroke-linecap="round"
                                                     stroke-linejoin="round"
                                                     class="feather feather-trash-2"
                                                   >
                                                      <polyline points="3 6 5 6 21 6"></polyline>
                                                      <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                                   </svg>
                                                </b-button>
                                             </td>
                                          </tr>

                                          <tr class="text-md-center" v-if="orderActual.movements.length < 1">
                                             <td colspan="6">
                                                <b-button variant="outline-dark" @click="addRow" class="mr-2">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-plus-circle">
                                                      <circle cx="12" cy="12" r="10"></circle>
                                                      <line x1="12" y1="8" x2="12" y2="16"></line>
                                                      <line x1="8" y1="12" x2="16" y2="12"></line>
                                                   </svg>
                                                </b-button>
                                                <b-button variant="outline-dark" @click="addRowCustom" v-if="$can('admin') || $can('office') || $can('technik-manager') || $can('obchod-manager') || $can('rola-pobocka')">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-plus-circle">
                                                      <circle cx="12" cy="12" r="10"></circle>
                                                      <line x1="12" y1="8" x2="12" y2="16"></line>
                                                      <line x1="8" y1="12" x2="16" y2="12"></line>
                                                   </svg>
                                                   Vlastné
                                                </b-button>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row>
                              <b-form-group class="col-md-12" label="Globálna poznámka">
                                 <b-form-textarea id="textarea" v-model="$v.form.note.$model" laceholder="Poznámka..."
                                                  rows="4"></b-form-textarea>
                              </b-form-group>
                           </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="cancel" class="mt-3">Zrušiť</b-button>
                           <b-button type="submit" variant="default" class="mt-3 ml-2">Uložiť</b-button>
                        </div>
                     </div>
                  </b-form>

               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">Nemáte oprávnění zobrazit tuto stránku.</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import { validationMixin } from "vuelidate";
   import { required } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";
   import "vue-multiselect/dist/vue-multiselect.min.css";
   import "../../../assets/sass/global/table.scss";

   export default {
      name: "edit",
      components: { Multiselect },
      mixins: [validationMixin],

      validations: {
         form: {
            name: {},
            identifier: {},
            from_user_id: {
               required
            },
            from_user_name: {
               required
            },
            to_user_id: {
               required
            },
            note: {},
            rows: {
               required
            }
         }
      },
      data() {
         return {
            orderActual: {},
            form: {
               name: "",
               identifier: "",
               from_user_id: [],
               from_user_name: "",
               to_user_id: null,
               note: "",
               rows: []
            },
            units: {},
            errors: []
         };
      },
      created() {
         this.$store.dispatch("user/getAllUsers");
         this.$store.dispatch("item/getItems", {all: 1});

         this.$axios.get("orders/" + this.$route.params.order)
           .then((response) => {
              this.orderActual = response.data;
           });

         this.$v.form.$touch();

         this.$axios.get("units")
           .then(response => {
              this.units = response.data;
           });
      },
      computed: {
         users() {
            return this.$store.state.user.users;
         },
         items() {
            const targetName = "note";
            const objectsArray = this.$store.state.item.items;
            for (let i = 0; i < objectsArray.length; i ++) {
               objectsArray[i][targetName] = "";
            }
            return objectsArray;
         },
         usersWithoutMe() {
            return this.users.filter(user => user.id !== this.form.from_user_id);
         }
      },
      watch: {
         orderActual(order) {
            this.form.name = order.name;
            this.form.identifier = order.identifier;
            this.form.from_user_id = order.from_user;
            this.form.from_user_name = order.from_user.name;
            this.form.to_user_id = { id: order.to_user.id, "name": order.to_user.name };
            this.form.note = order.note;
            let t = this;
            this.form.rows = [];
            order.items.forEach(function(value) {
               t.form.rows.push({
                  id: value.id,
                  name: value.name,
                  quantity: value.quantity,
                  note: value.note === null ? "" : value.note,
                  unit: value.unit
               });
            });
            // this.form.rows = order.rows;
         }
      },
      methods: {
         addRow() {
            this.form.rows.push({ id: 0, name: "", quantity: "", note: "", unit: "" });
         },
         addRowCustom() {
            this.form.rows.push({ id: null, name: "", quantity: "", note: "", unit: "" });
         },
         removeRow(index) {
            this.form.rows.splice(index, 1);
         },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         submitForm() {
            const t = this;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               t.$toast.error("Chýba dodávateľ alebo produkt");
               return;
            }

            this.form.rows.forEach(function(value) {
               if (value.id === 0) {
                  t.errors.push("Vyberte produkt");
                  if (value.quantity === "") {
                     t.errors.push("Zadajte množstvo");
                  }
               } else if (value.id !== 0 && value.id !== null) {
                  if (value.quantity === "") {
                     t.errors.push("Zadajte množstvo");
                  }
               } else if (value.id === null && value.name === "") {
                  t.errors.push("Zadajte názov produktu");
                  if (value.quantity === "") {
                     t.errors.push("Zadajte množstvo");
                  }
                  if (value.unit === "") {
                     t.errors.push("Zadajte jednotku");
                  }
               } else if (value.id === null && value.name !== "") {
                  if (value.quantity === "") {
                     t.errors.push("Zadajte množstvo");
                  }
                  if (value.unit === "") {
                     t.errors.push("Zadajte jednotku");
                  }
               }
            });


            if (t.errors.length > 0) {
               t.$toast.error(t.errors[0]);
               t.errors = [];
            } else {
               this.$v.form.$touch();
               if (this.$v.form.$anyError) {
                  t.$toast.error("Chýbajúce údaje vo formulári");
                  return;
               }

               let data = {
                  name: this.form.name,
                  identifier: this.form.identifier,
                  from_user_id: this.form.from_user_id.id,
                  to_user_id: this.form.to_user_id.id,
                  note: this.form.note,
                  _method: "PUT"

               };

               let formData = new FormData();

               for (let key in data) {
                  formData.append(key, data[key]);
               }

               // this.form.rows.forEach(function(value, index) {
               //    if (value.id !== null && value.id !== undefined) {
               //       formData.append("items[" + index + "][id]", value.id);// you have to add array symbol after the key name
               //       formData.append("items[" + index + "][quantity]", value.quantity);// you have to add array symbol after the key name
               //       formData.append("items[" + index + "][note]", value.note);// you have to add array symbol after the key name
               //    }
               // });

               const items = this.items;

               this.form.rows.forEach(function(value, index) {
                  const itemExists = items.find(item => item.id === value.id);

                  if (itemExists) {
                     formData.append("items[" + index + "][name]", "");
                     formData.append("items[" + index + "][id]", value.id);
                  } else {
                     formData.append("items[" + index + "][name]", value.name);
                     formData.append("items[" + index + "][id]", value.id);
                     formData.append("items[" + index + "][unit_id]", value.unit.id);

                  }
                  formData.append("items[" + index + "][quantity]", value.quantity);
                  formData.append("items[" + index + "][note]", value.note);
               });

               this.$axios.post("orders/" + this.orderActual.id, formData)
                 .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                       this.$store.dispatch("order/getOrder");
                       this.$store.dispatch("order/getOrders");
                       this.$router.go(- 1);
                       t.$toast.success(response.data.message);
                    } else {
                       t.$toast.error(response.data.message);
                    }
                 }).catch(error => {
                  t.$toast.error(error.response.data.message);
               });

            }
         }
      }
   };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
   .unit-multiselect {

      .multiselect__tags {
         border-radius: 0 0.2rem 0.2rem 0;
         background: #f1f2f3;
         min-height: 45.4px !important;
         border: 1px solid #bfc9d4;

      }

      .multiselect__single,
      .multiselect__input {
         background: #f1f2f3;
      }
   }
</style>
